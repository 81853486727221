import {
  Alert,
  Box,
  Button,
  Container,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  CircularProgress
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios,{ AxiosError } from 'axios';
import React, { useState, useContext, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import PasswordTips from '../components/layout/passwordtips';
import AccountStatusContext, {AccountStatus} from '../utils/contexts/AccountStatusContext';
import "../styles/changepassword.css";
import { error } from 'console';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMsgDialog from '../components/HandleMsgDialog';
import isDarkMode from '../utils/contexts/IsDarkMode';
import { useTranslation } from 'react-i18next';
import UserInfoContext from '../utils/contexts/UserInfoContext';
import {Navigate} from 'react-router-dom';
import moment from "moment";
import { isUMApp, PageModule } from '@um/js-api';

interface CheckNewPasswordInput {
  newPassword: string,
}

interface ChangePasswordInput {
  currentPassword: string,
  confirmPassword: string,
  verifiedPassword: string,
}

interface PasswordTipsProp {
      checked: boolean,
      disabled: boolean,
      color: string,
      icon: string,
      checkedIcon: string
}
interface UMAppContextType {
  isApp: boolean;
}

const contextValue: UMAppContextType = {
  isApp: isUMApp(),
};

const GetAccountStatus = () : boolean => {
  const accountStatus = useContext(AccountStatusContext);
  if (accountStatus === null || accountStatus === undefined) {
    return false
  } else {
    return true
  }
}

const ChangePassword  = () => {
  const {t} = useTranslation();
  if (isUMApp()) {
    PageModule.setTitle(`${t("changepassword.pagetitle")}`)
  }
  document.title="Change Password"
  sessionStorage.removeItem("prevPath");
  const [passwordLength, setPasswordLength] = useState<PasswordTipsProp>({checked: false, disabled: false, color: "#999999", icon: "close", checkedIcon: "check"});
  const [passwordMixedLetter, setPasswordMixedLetter] = useState<PasswordTipsProp>({checked:false, disabled:false, color:"", icon: "close", checkedIcon: "check"});
  const [passwordSpecialCharacter, setPasswordSpecialCharacter] = useState<PasswordTipsProp>({checked:false, disabled:false, color:"", icon: "close", checkedIcon: "check"});
  const [passwordDisallowedCharacter, setPasswordDisallowedCharacter] = useState<PasswordTipsProp>({checked:false, disabled:false, color:"", icon: "close", checkedIcon: "check"});
  const [passwordPassphrase, setPasswordPassphrase] = useState<PasswordTipsProp>({checked:false, disabled:false, color:"", icon: "close", checkedIcon: "check"});
  const [showStep1Box, setShowStep1Box] = useState(true);
  const [showStep2Box, setShowStep2Box] = useState(false);
  const [newPasswordType, setNewPasswordType] = useState(false);
  const [errorMsgDialogOpen, setErrorMsgDialogOpen] = useState(false);
  const [errorMsgDialogTitle, setErrorMsgDialogTitle] = useState(`${t("common.errtitle")}`);
  const [errorMsgDialogContent, setErrorMsgDialogContent] = useState(`${t("common.errdiaglog")}`);
  const [needReload, setNeedReload] = useState<boolean>(false);
  const [homeReload, setHomeReload] = useState<boolean>(false);
  const [needlogout, setNeedLogout] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false)
  // const userInfo = useContext(AccountStatusContext);
  const [accountStatus, setAccountStatus] = useState<AccountStatus | null>(null);
  const isDark = useContext(isDarkMode);
  // const token = Cookies.get('XSRF-TOKEN');

  // console.log("in change password page, Get is Dark mode: " + isDark)

  const schema = Yup.object().shape({
    confirmPassword: Yup.string()
          .trim()
          .required()
          .min(9)
          .max(30)
          .oneOf([Yup.ref('verifiedPassword'), ''], `${t("changepassword.pwd_not_match")}`),
  });

  useEffect(() => {
    if (accountStatus === null || accountStatus === undefined) {
      axios
        .get(`${process.env.REACT_APP_CONTEXT_PATH}/api/account/get_account_status`)
        .then((resp) => {
          setAccountStatus(resp.data.data as AccountStatus);
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });
    }
  }, [accountStatus]);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CheckNewPasswordInput>({
    defaultValues: {
      newPassword:""
    }
  });
  
  const {
    control: control2,
    register: register2,
    watch: watch2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    formState: { errors: errors2 },
  } = useForm<ChangePasswordInput>({
    mode: "onChange",
    defaultValues: {
      currentPassword: "",
      confirmPassword: "",
      verifiedPassword: ""
    },
    resolver: yupResolver(schema),
  });

  const togglePassword = () => setNewPasswordType((show) => !show);

  const handleMsgDialogOpen = (title: string, content: string) => {
    setErrorMsgDialogTitle(title);
    setErrorMsgDialogContent(content);
    setErrorMsgDialogOpen(true);
  };

  const handleMsgDialogClose = () => {
    setErrorMsgDialogOpen(false);
  };

  const backToStep1 = () => {
    setShowStep1Box(true);
    setShowStep2Box(false);
    setValue("newPassword","");
  };

  const watchFields = watch("newPassword");

  const confirmPassword = register2('confirmPassword', {minLength: 9});

  function checkPasswordTips(password: string) {
    var regex1 = new RegExp("^.{9,30}$");
    if (regex1.test(password)) {
      setPasswordLength({checked:true, disabled:false, color:"#8bc34a", icon: "close", checkedIcon: "check"});
    } else {
      setPasswordLength({checked:false, disabled:false, color:"#ff6e40", icon: "close", checkedIcon: "check"});
    }
    
    var regex2 = new RegExp("(?=.*[a-z])(?=.*[A-Z])");
    if (regex2.test(password)) {
      setPasswordMixedLetter({checked:true, disabled:false, color:"#8bc34a", icon: "close", checkedIcon: "check"});
    } else {
      setPasswordMixedLetter({checked:false, disabled:false, color:"#ff6e40", icon: "close", checkedIcon: "check"});
    }
    
    var regex3 = new RegExp(/\d+/);
    var regex3_2 = new RegExp(/[\!\#\$\%\^\&\*\(\)\_\+\-\`\{\|\}\|\[\]\\\:\"\;\'\?\,\.]+/)
    if (regex3.test(password) && regex3_2.test(password)) {
      setPasswordSpecialCharacter({checked:true, disabled:false, color:"#8bc34a", icon: "close", checkedIcon: "check"});
    } else {
      setPasswordSpecialCharacter({checked:false, disabled:false, color:"#ff6e40", icon: "close", checkedIcon: "check"});
    }
    
    var regex4 = new RegExp(/\s+|[\@\=\/\<\>]+/);
    if (regex4.test(password)) {
      setPasswordDisallowedCharacter({checked:false, disabled:false, color:"#ff6e40", icon: "close", checkedIcon: "check"});
    } else {
      setPasswordDisallowedCharacter({checked:true, disabled:false, color:"#8bc34a", icon: "close", checkedIcon: "check"});
    }
    
    var regex5 = new RegExp("^.{16}");
    if (regex5.test(password)) {
      //setPasswordMixedLetter({checked:true, disabled:true, color:"#8bc34a", icon: "remove", checkedIcon: "remove"});
      setPasswordSpecialCharacter({checked:true, disabled:true, color:"#8bc34a", icon: "remove", checkedIcon: "remove"});
      setPasswordPassphrase({checked:true, disabled:false, color:"#8bc34a", icon: "recommend", checkedIcon: "recommend"});
    } else {
      setPasswordPassphrase({checked:false, disabled:true, color:"", icon: "recommend", checkedIcon: "recommend"});
    }
  }

  React.useEffect(() => {
    checkPasswordTips(watchFields);
  }, [watchFields]);

  const onCheckNewPasswordSubmit: SubmitHandler<CheckNewPasswordInput> = async(data) => {
    if(accountStatus==null ){
      throw new Error("Error: Userinfo is null");
    }

    if(!accountStatus.username){
      throw new Error("Error: Username undefined");
    }

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_CONTEXT_PATH}/api/account/verify_password`,
        data: {"username": accountStatus.username, "newPassword": data.newPassword},
      });
      if (response.status===200) {
        // console.log(response.data);
        if (response.data.code===600) {
          handleMsgDialogOpen(`${t("common.changepassword")} (${response.data.pcode})`, response.data.data.data);
        } else {
          setValue2("verifiedPassword", data.newPassword);
          setShowStep1Box(false);
          setShowStep2Box(true);
        }
      } else {
        handleMsgDialogOpen(`${t("common.serviceErr")}`, `${t("changepassword.new_pwd_check_err")}`);
        // throw new Error("Error: Not able to connect verify password API");
      }
    } catch(error) {
      // console.log('Error Code')
      handleMsgDialogOpen(`${t("common.serviceErr")}`, String(error));
    }
  };
  
  const onChangePasswordSubmit: SubmitHandler<ChangePasswordInput> = async(data) => {
    setIsLoading(true);
    if(accountStatus==null ){
      throw new Error("Error: Userinfo is null");
    }

    if(!accountStatus.username){
      throw new Error("Error: Username undefined");
    }

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_CONTEXT_PATH}/api/account/change_password`,
        data: {"username": accountStatus.username, "oldPassword": data.currentPassword, "confirmPassword": data.confirmPassword, "newPassword": data.verifiedPassword},
      });
      if (response.status===200) {
        setIsLoading(false);
        // console.log(response.data);
        if (response.data.code===600) {
          handleMsgDialogOpen(`${t("common.changepassword")} (${response.data.pcode})`, response.data.data.data);
          setNeedReload(false);
        } else {
          handleMsgDialogOpen(`${t("common.changepassword")}`, `${t("changepassword.success")}`);
          setNeedReload(false);
          setHomeReload(true);
          setNeedLogout(true);
        }
      } else {
        setIsLoading(false);
        handleMsgDialogOpen(`${t("changepassword.errheader")}`, `${t("changepassword.errmsg")}`)
        // throw new Error("Error: Not able to connect verify password API");
      }
    } catch(error) {
      setIsLoading(false);
      handleMsgDialogOpen(`${t("common.serviceErr")}`, String(error))
      setNeedReload(false);
      console.log(error)
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
       {!GetAccountStatus() && (
          <CircularProgress
                size={35}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
        )}
      <Container disableGutters sx={{ width: '98%', justifyContent: 'center', alignContent: 'center' }}>
        <Typography variant="h5" color="text.primary" sx={{display: "flex", justifyContent: "center", mt: 2, fontWeight: "600"}}>{t("changepassword.contextTitle")}</Typography>
        <Alert severity='warning' sx={{ margin: 2 }}>
          {t("changepassword.warning1")}
        </Alert>
        { accountStatus?.passwordLastSet !== null && accountStatus?.passwordLastSet !== undefined ? 
          ( 
            <Alert severity='success' sx={{ margin: 2 }} >
              {t('changepassword.pwd_last_set')}{moment(accountStatus?.passwordLastSet).format("YYYY-MM-DD HH:mm:ss")}
            </Alert>
          ) : null
        }       
         <Box className='step1' sx={{width: '100%', justifyContent: 'center', alignContent: 'center', display: showStep1Box ? 'block' : 'none'}}>
          <Box className='step1Tips' sx={{display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center' }}>
            <PasswordTips result={[passwordLength,passwordMixedLetter,passwordSpecialCharacter,passwordDisallowedCharacter,passwordPassphrase]} />
          </Box>
          <Box className='step1Form' sx={{display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center'}}>
            <form className='changePasswordForm' onSubmit={handleSubmit(onCheckNewPasswordSubmit)}>
              <section>
                  <Controller
                    name='newPassword'
                    control={control}
                    render={({ field }) => <TextField label={t("changepassword.password_label")} type={newPasswordType ? 'text' : 'password'} sx={{width: '80%',}} InputProps={{
                      endAdornment: <InputAdornment position="end"><IconButton aria-label="toggle password" onClick={togglePassword} edge="end">{newPasswordType ? <VisibilityOff /> : <Visibility />}</IconButton></InputAdornment>,
                    }} {...field} />}
                  />
                  <p>{errors.newPassword?.message}</p>
                  <Button variant="contained" type='submit' disabled={!(passwordLength.checked && passwordMixedLetter.checked && passwordSpecialCharacter.checked && passwordDisallowedCharacter.checked)}>{t("button.next")}</Button>
              </section>
            </form>
          </Box>
        </Box>

        <Box className='step2' sx={{width: '100%', justifyContent: 'center', alignContent: 'center', display: showStep2Box ? 'block' : 'none' }}>
          <Box className='step2Form' sx={{display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center'}}>
            <form className='changePasswordForm2' onSubmit={handleSubmit2(onChangePasswordSubmit)}>
              <section>
                  <Controller
                    name='currentPassword'
                    control={control2}
                    render={({ field }) => <TextField label={t("changepassword.old_pwd_label")} type="password" sx={{width: '80%'}}  {...field} />}
                  />
                  <p>{errors2.currentPassword?.message}</p>
                  <Controller
                    name='confirmPassword'
                    control={control2}
                    render={({ field }) => <TextField label={t("changepassword.confirm_pwd_label")} type="password" sx={{width: '80%'}}  {...field} />}
                  />
                  <Typography variant='subtitle2' color="error.main" sx={{ my: 2}}>{errors2.confirmPassword?.message}</Typography>
                  <input type="hidden" name="verifiedPassword" />
                  <LoadingButton variant="contained" type='submit' loading={isLoading} >{t("button.change")}</LoadingButton>
                  <Button variant="outlined" onClick={backToStep1}>{t("button.back")}</Button>
              </section>
            </form>
          </Box>
        </Box>
      </Container>

      <ErrorMsgDialog open={errorMsgDialogOpen} onClose={handleMsgDialogClose} errorTitle={errorMsgDialogTitle} errorContent={errorMsgDialogContent} needReload={needReload} homeReload={homeReload} needLogout={needlogout}/>
    </Box>
  );
  // }
  
};

export default ChangePassword;
