import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from '@mui/material';
import React, { useState } from 'react';
import { isUMApp, AppModule, AccountModule } from '@um/js-api';
import { Trans, withTranslation, useTranslation  } from 'react-i18next';
import Cookies from 'js-cookie';

export interface ErrorMsgDialogProps {
    open: boolean;
    needReload: boolean;
    homeReload?: boolean;
    needLogout?: boolean;
    errorTitle: string;
    errorContent: string;
    onClose: () => void;
}
interface UMAppContextType {
  isApp: boolean;
}
const contextValue: UMAppContextType = {
  isApp: isUMApp(),
};

export default function ErrorMsgDialog(props: ErrorMsgDialogProps) {
    const { onClose, open, errorTitle, errorContent, needReload, homeReload, needLogout } = props;
    const handleClose = () => {
      onClose();
      if (needReload) {
        window.location.reload()
      }
      if (homeReload !== null) {
        if (homeReload) {
          if (isUMApp()) {
            AppModule.closePage()
          } else {
            window.location.replace("https://umpass.um.edu.mo");
          }
        }
      }
      if (needLogout) {
        logout(); 
      }
    };

    const logout = () => {
      const token = Cookies.get('XSRF-TOKEN');
      if (isUMApp()) {
        AccountModule.logout();
      } else {
        if (token) {
          const form = document.createElement('form');
          form.action = `${process.env.REACT_APP_CONTEXT_PATH}/logout?_csrf=${token}`;
          form.method = 'post';
          form.style.display = 'none';
    
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = '_csrf';
          input.value = token;
          form.appendChild(input);
    
          document.body.appendChild(form);
          form.submit();
        }
      }
    };

    const { t } = useTranslation();
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {errorTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{whiteSpace: 'pre-line'}}>
            {errorContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>{t("button.close")}</Button>
        </DialogActions>
      </Dialog>
    );
  }
